import React, {  useState } from "react";
import { Sidebar, Menu, MenuItem, SubMenu } from "react-pro-sidebar";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { connect } from "react-redux";
import logo from ".././images/Forcesight_Logo.svg";
import Fs_icon from ".././images/forcesightIcon.png";



const mapStateToProps = (state) => {
  return {
    sliderCollapsed: state.filter.sliderCollapsed, 
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    updateSliderFilter: (sliderInfo) =>
      dispatch({
        type: "filter/updateSliderFilter",
        payload: {
          sliderCollapsed: sliderInfo.sliderCollapsed,
        },
      }),
  };
};

function CustomSideBar({ children, sliderCollapsed, ...props }) {
  const data = JSON?.parse(localStorage.getItem("cmpy_info")) || {};
  const userEmail = JSON.parse(localStorage.getItem("user_info")) || {};
  const isLoginAdmin = JSON.parse(localStorage.getItem("isLoginAdmin")) || false;

  

  const location = useLocation();
  const history = useNavigate();
  const [optimizeProfitOpen, setOptimizeProfitOpen] = useState(
    !!(
      location.pathname === "/profitability/0" ||
      location.pathname === "/profitability/2" ||
      location.pathname === "/profit-loss-monthly" ||
      location.pathname === "/profit-loss-comparison" ||
      location.pathname === "/return-insights/2" ||
      location.pathname === "/return-insights/0" ||
      location.pathname === "/invoice-table" 
    )
  );
  const [optimizeCashOpen, setOptimizeCashOpen] = useState(
    !!(
      location.pathname === "/optimize-cash" ||
      location.pathname === "/payment-tracker/0" ||
      location.pathname === "/payment-tracker/1" ||
      location.pathname === "/outstanding-payments" ||
      location.pathname === "/payment-tracker/2" ||
      location.pathname === "/payment-tracker/3" ||
      location.pathname === "/invoice-summary-Amazon-India" ||
      location.pathname === "/invoice-summary-Flipkart" ||
      location.pathname === "/inventory/0" ||
      location.pathname === "/inventory/1" ||
      location.pathname === "/inventory/2" ||
      location.pathname === "/inventory/3" ||
      location.pathname === "/claims" ||
      location.pathname === "/amazonvcp-recon"
    )
  );
  const [alternativeViewOpen, setAlternativeViewOpen] = useState(
    !!(
     
      location.pathname === "/return-insights/2" ||
      location.pathname === "/return-insights/0" ||
      location.pathname === "/invoice-table" 
    )
  );
  const [paymentsOpen, setPaymentsOpen] = useState(
    !!(
      location.pathname === "/payment-tracker/0" ||
      location.pathname === "/payment-tracker/1" ||
      location.pathname === "/outstanding-payments" ||
      location.pathname === "/payment-tracker/2" ||
      location.pathname === "/payment-tracker/3" ||
      location.pathname === "/invoice-summary-Amazon-India" ||
      location.pathname === "/invoice-summary-Flipkart"
    )
  );
  const [inventoryOpen, setInventoryOpen] = useState(
    !!(
      location.pathname === "/inventory/0" ||
      location.pathname === "/inventory/1" ||
      location.pathname === "/inventory/2" ||
      location.pathname === "/inventory/3"
    )
  );
  const [reconOpen, setReconOpen] = useState(
    !!(
      location.pathname === "/claims" ||
      location.pathname === "/amazonvcp-recon"
    )
  );
  const [reviewsOpen, setReviewsOpen] = useState(
    !!(
      location.pathname === "/reviews/0" ||
      location.pathname === "/reviews/1" ||
      location.pathname === "/reviews/3" ||
      location.pathname === "/reviews/4" ||
      location.pathname === "/reviews/5"
    )
  );
  const [productIntelOpen, setProductIntelOpen] = useState(
    !!(
      location.pathname === "/keywords-tracker/0" ||
      location.pathname === "/keywords-tracker/1" ||
      location.pathname === "/keywords-tracker/2" ||
      location.pathname === "/keywords-tracker/3" ||
      location.pathname === "/price-tracker/2" ||
      location.pathname === "/price-tracker/3" ||
      location.pathname === "/PricetrackerV2" ||
      location.pathname === "/sales-defence/1" ||
      location.pathname === "/buybox-multiple-pincode"
    )
  );
  const [keywordsOpen, setKeywordsOpen] = useState(
    !!(
      location.pathname === "/keywords-tracker/0" ||
      location.pathname === "/keywords-tracker/1" ||
      location.pathname === "/keywords-tracker/2" ||
      location.pathname === "/keywords-tracker/3"
    )
  );
  const [complianceViewOpen, setComplianceViewOpen] = useState(
    !!(
      location.pathname === "/price-tracker/2" ||
      location.pathname === "/price-tracker/3" 
    )
  );
  const [productSettingsOpen, setProductSettingsOpen] = useState(
    !!(
      location.pathname === "/productconfig/0" ||
      // location.pathname === "/profitability/3" ||
      location.pathname === "/productconfig/1" ||
      location.pathname === "/productconfig/2" ||
      location.pathname === "/productconfig/3" ||
      location.pathname === "/cashback" ||
      location.pathname === "/other-expenses" ||
      location.pathname === "/reviews/6" ||
      location.pathname === "/sales-defence/2" ||
      location.pathname === "/add-exception-rules"
    )
  );
  const [invoiceReconOpen, setInvoiceReconOpen] = useState(
    !!(
      location.pathname === "/invoice-summary-Amazon-India" ||
      location.pathname === "/invoice-summary-Flipkart"
    )
  );
  const [buyboxOpen, setBuyboxOpen] = useState(
    !!(
      location.pathname === "/sales-defence/1" ||
      location.pathname === "/buybox-multiple-pincode"
    )
  );
  const [userSettingsOpen, setUserSettingsOpen] = useState(
    !!(
      location.pathname === "/account-settings/account" ||
      location.pathname === "/account-settings/marketplace" ||
      location.pathname === "/account-settings/user"
    )
  );

  const handleOptimizeProfitClick = () => {
    setOptimizeProfitOpen((prevState) => !prevState);
  };
  const handleOptimizeCashClick = () => {
    setOptimizeCashOpen((prevState) => !prevState);
  };
  const handleAlternativeViewClick = () => {
    setAlternativeViewOpen((prevState) => !prevState);
  };
  const handlePaymentsClick = () => {
    setPaymentsOpen((prevState) => !prevState);
  };
  const handleInventoryClick = () => {
    setInventoryOpen((prevState) => !prevState);
  };
  const handleReconClick = () => {
    setReconOpen((prevState) => !prevState);
  };
  const handleReviewsClick = () => {
    setReviewsOpen((prevState) => !prevState);
  };
  const handleProductIntelClick = () => {
    setProductIntelOpen((prevState) => !prevState);
  };
  const handleKeywordsClick = () => {
    setKeywordsOpen((prevState) => !prevState);
  };
  const handleComplianceViewClick = () => {
    setComplianceViewOpen((prevState) => !prevState);
  };
  const handleProductSettingsClick = () => {
    setProductSettingsOpen((prevState) => !prevState);
  };
  const handleInvoiceReconClick = () => {
    setInvoiceReconOpen((prevState) => !prevState);
  };
  const handleBuyboxClick = () => {
    setBuyboxOpen((prevState) => !prevState);
  };

  const handleUserSettingsClick = () => {
    setUserSettingsOpen((prevState) => !prevState);
  };
  const handleSliderCollapse = () => {
    props.updateSliderFilter({
      sliderCollapsed: !sliderCollapsed,
    });
  };

  const getMenuItemStyles = (path) => {
    const isActive = location.pathname === path;
    return {
      backgroundColor: isActive ? "#3874FF" : "",
      color: isActive ? "#fff" : "",
      borderRadius: isActive ? "5px" : "",
    };
  };
  const handleLogoutClick = (e) => {
    if (!localStorage.getItem("fs_token")) {
      localStorage.clear();
      history("/login");
    } else {
      let admin_token = localStorage.getItem("fs_token");
      let admin_refresh_token = localStorage.getItem("fs_refresh_token");
      let user_info = localStorage.getItem("fs_user_info");
      let cmpy_info = localStorage.getItem("fs_cmpy_info");
      localStorage.setItem("token", admin_token);
      localStorage.setItem("refresh_token", admin_refresh_token);
      localStorage.setItem("user_info", user_info);
      localStorage.setItem("cmpy_info", cmpy_info);
      localStorage.removeItem("fs_user_info");
      localStorage.removeItem("fs_cmpy_info");
      localStorage.removeItem("fs_token");
      localStorage.removeItem("fs_refresh_token");
      localStorage.removeItem("subscriptionDays");
     // common filter 
      localStorage.removeItem("profitFilterData")
      localStorage.removeItem("ratingFilter");
      localStorage.removeItem("orderStatusFilter");
      localStorage.removeItem("returnStatusFilter");
      localStorage.removeItem("fullfilmemtFilter");
      localStorage.removeItem("warehouseFilter");
      localStorage.removeItem("masterSetFilter");
      localStorage.removeItem("finalMasterSetFilter");

      history("/admin-dashboard");
    }
  };
  const handleDashboardClick = (e) => {
    history( userEmail?.email === "gitasureka@yahoo.co.in" && !isLoginAdmin ? "/profitability/2" : "/profitability/0");
  };

  return (
    // <div style={{ display: "flex", height: "100%", minHeight: "400px" }}>
    <div className="mainWrapper">
      <Sidebar
        backgroundColor="#2B3990"
        collapsed={sliderCollapsed}
        className="fixed h-screen  "
      >
        <div className="flex justify-between bg-white">
          <div className="logoHolder px-3 pt-3 pb-2">
            <div
              className="flex-shrink-0 flex items-center"
              onClick={handleDashboardClick}
            >
              <Link
                to={
                  userEmail?.email === "gitasureka@yahoo.co.in" && !isLoginAdmin
                    ? "/profitability/2"
                    : "/profitability/0"
                }
              >
                <img
                  className={`${
                    sliderCollapsed
                      ? "block lg:hidden h-4 w-auto cursor-pointer"
                      : "block lg:hidden h-8 w-auto cursor-pointer"
                  }`}
                  src={sliderCollapsed === true ? Fs_icon : logo}
                  alt="Workflow"
                />
              </Link>
              <Link
                to={
                  userEmail?.email === "gitasureka@yahoo.co.in" && !isLoginAdmin
                    ? "/profitability/2"
                    : "/profitability/0"
                }
              >
                <img
                  className={`${
                    sliderCollapsed
                      ? "hidden lg:block h-4 w-auto "
                      : "hidden lg:block h-8 w-auto "
                  }`}
                  src={sliderCollapsed === true ? Fs_icon : logo}
                  alt="Workflow"
                />
              </Link>
            </div>
          </div>
          <div
            onClick={handleSliderCollapse}
            className="cursor-pointer text-black flex justify-end px-3 pt-3"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke-width="1.5"
              stroke="currentColor"
              class="w-6 h-6"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                d="M3.75 6.75h16.5M3.75 12H12m-8.25 5.25h16.5"
              />
            </svg>
          </div>
        </div>

        <Menu
          style={{
            paddingTop: "10px",
            paddingBottom: "30px",
            maxHeight: "calc(100vh - 80px)",
            overflowY: "auto",
          }}
        >
          <Menu
            menuItemStyles={{
              button: {
                // the active class will be added automatically by react router
                // so we can use it to style the active menu item
                [`&.active`]: {
                  backgroundColor: "#13395e",
                  color: "#fff",
                },
              },
            }}
          >
            <MenuItem
              icon={
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke-width="1.5"
                  stroke="currentColor"
                  class="w-5 h-5"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    d="M14 5l7 7m0 0l-7 7m7-7H3"
                  />
                </svg>
              }
              component={<Link to="/action-required" />}
              style={getMenuItemStyles("/action-required")}
            >
              Action Required
            </MenuItem>
            <MenuItem
              icon={
                <svg 
                xmlns="http://www.w3.org/2000/svg"
                stroke-width="1.5"
                stroke="currentColor"
                fill="currentColor"
                class="w-5 h-5"
                width="224.000000pt" height="225.000000pt" viewBox="0 0 224 225"
               >
               
               <g transform="translate(0.000000,225.000000) scale(0.100000,-0.100000)">
               <path d="M848 2239 c-204 -30 -412 -133 -555 -276 -139 -139 -233 -316 -274
               -518 -18 -86 -15 -284 6 -379 77 -363 350 -648 715 -748 73 -20 108 -23 250
               -22 137 0 178 4 243 22 87 24 176 61 252 105 l50 29 30 -34 c16 -19 108 -121
               204 -226 l174 -191 76 69 c42 37 108 98 146 133 l70 65 -26 28 c-14 16 -109
               119 -211 230 l-185 201 53 109 c66 133 93 236 101 375 22 392 -182 749 -532
               929 -173 90 -395 127 -587 99z m369 -128 c328 -94 567 -355 630 -686 19 -97
               13 -290 -12 -380 -25 -94 -108 -253 -169 -327 -122 -147 -298 -256 -480 -298
               -83 -20 -115 -22 -234 -17 -211 8 -359 62 -517 191 -151 122 -249 277 -302
               476 -25 98 -23 310 6 415 89 329 343 568 681 640 93 21 303 13 397 -14z"/>
               <path d="M1043 1742 c-78 -43 -144 -80 -147 -83 -4 -3 24 -17 61 -31 73 -27
               76 -32 60 -103 -8 -31 -8 -31 60 -49 37 -10 75 -21 84 -23 14 -4 18 5 24 46 4
               32 12 51 20 51 7 0 43 -9 80 -21 37 -11 68 -19 70 -17 4 5 -159 308 -165 307
               -3 0 -69 -35 -147 -77z"/>
               <path d="M474 1597 c-3 -8 -4 -193 -2 -411 l3 -398 24 -19 c22 -18 46 -19 527
               -19 l504 0 0 55 0 55 -475 0 -475 0 0 375 0 375 -50 0 c-33 0 -52 -5 -56 -13z"/>
               <path d="M712 1203 l3 -248 83 -3 82 -3 0 251 0 250 -85 0 -85 0 2 -247z"/>
               <path d="M1000 1175 l0 -226 83 3 82 3 3 200 2 201 -75 22 c-41 12 -79 22 -85
               22 -6 0 -10 -79 -10 -225z"/>
               <path d="M1272 1113 l3 -158 83 -3 82 -3 0 161 0 160 -85 0 -86 0 3 -157z"/>
               </g>
               </svg>
              
              }
              component={<Link to="/profit-analysis" />}
              style={getMenuItemStyles("/profit-analysis")}
            >
              Sales Summary
            </MenuItem>
            <SubMenu
              title="Optimize Profits"
              open={optimizeProfitOpen}
              onClick={handleOptimizeProfitClick}
              icon={
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke-width="1.5"
                  stroke="currentColor"
                  class="w-5 h-5"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    d="M2.25 18L9 11.25l4.306 4.307a11.95 11.95 0 015.814-5.519l2.74-1.22m0 0l-5.94-2.28m5.94 2.28l-2.28 5.941"
                  />
                </svg>
              }
              label="Optimize Profits"
              component={
                <Link
                  to={
                    userEmail?.email === "gitasureka@yahoo.co.in" &&
                    !isLoginAdmin
                      ? "/profitability/2"
                      : "/profitability/0"
                  }
                />
              }
              style={getMenuItemStyles("/profitability/0")}
            >
              <MenuItem
                component={<Link to="/profitability/2" />}
                style={getMenuItemStyles("/profitability/2")}
              >
                Profit Table
              </MenuItem>
              <MenuItem
                component={<Link to="/profit-loss-monthly" />}
                style={getMenuItemStyles("/profit-loss-monthly")}
              >
                Monthly Summary
              </MenuItem>
              <MenuItem
                component={<Link to="/profit-loss-comparison" />}
                style={getMenuItemStyles("/profit-loss-comparison")}
              >
                PoP Comparison
              </MenuItem>
              <SubMenu
                title="Alternate View"
                label="Alternate View"
                open={alternativeViewOpen}
                onClick={handleAlternativeViewClick}
              >
                <MenuItem
                  component={<Link to="/return-insights/0" />}
                  style={getMenuItemStyles("/return-insights/0")}
                >
                  Returns
                </MenuItem>
              </SubMenu>
            </SubMenu>
            <SubMenu
              title="Optimize Cash"
              open={optimizeCashOpen}
              onClick={handleOptimizeCashClick}
              icon={
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke-width="1.5"
                  stroke="currentColor"
                  class="w-5 h-5"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    d="M3 13.125C3 12.504 3.504 12 4.125 12h2.25c.621 0 1.125.504 1.125 1.125v6.75C7.5 20.496 6.996 21 6.375 21h-2.25A1.125 1.125 0 013 19.875v-6.75zM9.75 8.625c0-.621.504-1.125 1.125-1.125h2.25c.621 0 1.125.504 1.125 1.125v11.25c0 .621-.504 1.125-1.125 1.125h-2.25a1.125 1.125 0 01-1.125-1.125V8.625zM16.5 4.125c0-.621.504-1.125 1.125-1.125h2.25C20.496 3 21 3.504 21 4.125v15.75c0 .621-.504 1.125-1.125 1.125h-2.25a1.125 1.125 0 01-1.125-1.125V4.125z"
                  />
                </svg>
              }
              label="Optimize Cash"
              component={<Link to="/optimize-cash" />}
              style={getMenuItemStyles("/optimize-cash")}
            >
              <SubMenu
                title="Payments"
                label="Payments"
                component={<Link to="/payment-tracker/0" />}
                style={getMenuItemStyles("/payment-tracker/0")}
                open={paymentsOpen}
                onClick={handlePaymentsClick}
              >
                <MenuItem
                  component={<Link to="/outstanding-payments" />}
                  style={getMenuItemStyles("/outstanding-payments")}
                >
                  OutStanding Payments
                </MenuItem>
                <MenuItem
                  component={<Link to="/payment-tracker/1" />}
                  style={getMenuItemStyles("/payment-tracker/1")}
                >
                  Settled Orders
                </MenuItem>
                <MenuItem
                  component={<Link to="/payment-tracker/2" />}
                  style={getMenuItemStyles("/payment-tracker/2")}
                >
                  Unsettled Orders
                </MenuItem>
                <MenuItem
                  component={<Link to="/payment-tracker/3" />}
                  style={getMenuItemStyles("/payment-tracker/3")}
                >
                  {"Bank View"}
                </MenuItem>
                <SubMenu
                  title=" Invoice Reconciliation"
                  label=" Invoice(Beta)"
                  open={invoiceReconOpen}
                  onClick={handleInvoiceReconClick}
                >
                  <MenuItem
                    component={<Link to="/invoice-summary-Amazon-India" />}
                    style={getMenuItemStyles("/invoice-summary-Amazon-India")}
                  >
                    Amazon-India
                  </MenuItem>
                  <MenuItem
                    component={<Link to="/invoice-summary-Flipkart" />}
                    style={getMenuItemStyles("/invoice-summary-Flipkart")}
                  >
                    Flipkart
                  </MenuItem>
                </SubMenu>
              </SubMenu>

              <SubMenu
                title="Inventory"
                label="Inventory"
                component={<Link to="/inventory/0" />}
                style={getMenuItemStyles("/inventory/0")}
                open={inventoryOpen}
                onClick={handleInventoryClick}
              >
                <MenuItem
                  component={<Link to="/inventory/2" />}
                  style={getMenuItemStyles("/inventory/2")}
                >
                  Ledger
                </MenuItem>
                <MenuItem
                  component={<Link to="/inventory/3" />}
                  style={getMenuItemStyles("/inventory/3")}
                >
                  Restock
                </MenuItem>
                <MenuItem
                  component={<Link to="/inventory/1" />}
                  style={getMenuItemStyles("/inventory/1")}
                >
                  Slow Moving
                </MenuItem>
              </SubMenu>
              <SubMenu
                title="Recon(Beta)"
                label="Recon(Beta)"
                open={reconOpen}
                onClick={handleReconClick}
              >
                <MenuItem
                  component={<Link to="/claims" />}
                  style={getMenuItemStyles("/claims")}
                >
                  Flipkart Late Returns
                </MenuItem>
                <MenuItem
                  component={<Link to="/amazonvcp-recon" />}
                  style={getMenuItemStyles("/amazonvcp-recon")}
                >
                  AmazonVCP Recon
                </MenuItem>
              </SubMenu>
            </SubMenu>
            <SubMenu
              title="Brand Intelligence"
              icon={
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke-width="1.5"
                  stroke="currentColor"
                  class="w-5 h-5"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    d="M7.5 8.25h9m-9 3H12m-9.75 1.51c0 1.6 1.123 2.994 2.707 3.227 1.129.166 2.27.293 3.423.379.35.026.67.21.865.501L12 21l2.755-4.133a1.14 1.14 0 01.865-.501 48.172 48.172 0 003.423-.379c1.584-.233 2.707-1.626 2.707-3.228V6.741c0-1.602-1.123-2.995-2.707-3.228A48.394 48.394 0 0012 3c-2.392 0-4.744.175-7.043.513C3.373 3.746 2.25 5.14 2.25 6.741v6.018z"
                  />
                </svg>
              }
              label="Brand Intelligence"
              component={<Link to="/reviews/0" />}
              style={getMenuItemStyles("/reviews/0")}
              open={reviewsOpen}
              onClick={handleReviewsClick}
            >
              <MenuItem
                component={<Link to="/reviews/1" />}
                style={getMenuItemStyles("/reviews/1")}
              >
                Product View
              </MenuItem>
              <MenuItem
                component={<Link to="/reviews/3" />}
                style={getMenuItemStyles("/reviews/3")}
              >
                Review Analyser
              </MenuItem>
              <MenuItem
                component={<Link to="/reviews/4" />}
                style={getMenuItemStyles("/reviews/4")}
              >
                Campaign
              </MenuItem>
              <MenuItem
                component={<Link to="/reviews/5" />}
                style={getMenuItemStyles("/reviews/5")}
              >
                Seller Reviews
              </MenuItem>
            </SubMenu>
            <SubMenu
              title="Product Intelligence"
              icon={
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke-width="1.5"
                  stroke="currentColor"
                  data-slot="icon"
                  class="w-5 h-5"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    d="m3.75 13.5 10.5-11.25L12 10.5h8.25L9.75 21.75 12 13.5H3.75Z"
                  />
                </svg>
              }
              label="Product Intelligence"
              open={productIntelOpen}
              onClick={handleProductIntelClick}
            >
              <SubMenu
                title="Keyword"
                label="Keyword"
                style={getMenuItemStyles("/keywords-tracker/0")}
                open={keywordsOpen}
                onClick={handleKeywordsClick}
              >
                <MenuItem
                  component={<Link to="/keywords-tracker/1" />}
                  style={getMenuItemStyles("/keywords-tracker/1")}
                >
                  Identify Products
                </MenuItem>
                {/* <MenuItem
                  component={<Link to="/keywords-tracker/3" />}
                  style={getMenuItemStyles("/keywords-tracker/3")}
                >
                  Url Rotator
                </MenuItem> */}
              </SubMenu>
              <MenuItem
                component={<Link to="/PricetrackerV2" />}
                style={getMenuItemStyles("/PricetrackerV2")}
              >
                Pricing
              </MenuItem>
              <SubMenu
                title="Buybox"
                label="Buybox"
                component={<Link to="/sales-defence/1" />}
                style={getMenuItemStyles("/sales-defence/1")}
                open={buyboxOpen}
                onClick={handleBuyboxClick}
              >
                <MenuItem
                  component={<Link to="/buybox-multiple-pincode" />}
                  style={getMenuItemStyles("/buybox-multiple-pincode")}
                >
                  Mulitple Pincodes
                </MenuItem>
              </SubMenu>

              <SubMenu
                title="Compliance"
                label="Compliance"
                open={complianceViewOpen}
                onClick={handleComplianceViewClick}
              >
                <MenuItem
                  component={<Link to="/price-tracker/3" />}
                  style={getMenuItemStyles("/price-tracker/3")}
                >
                  Price Compliance
                </MenuItem>
                <MenuItem
                  component={<Link to="/price-tracker/2" />}
                  style={getMenuItemStyles("/price-tracker/2")}
                >
                  Discount Compliance
                </MenuItem>
              </SubMenu>
            </SubMenu>
            <SubMenu
              title="Product Settings"
              icon={
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke-width="1.5"
                  stroke="currentColor"
                  class="w-5 h-5"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    d="M10.343 3.94c.09-.542.56-.94 1.11-.94h1.093c.55 0 1.02.398 1.11.94l.149.894c.07.424.384.764.78.93.398.164.855.142 1.205-.108l.737-.527a1.125 1.125 0 011.45.12l.773.774c.39.389.44 1.002.12 1.45l-.527.737c-.25.35-.272.806-.107 1.204.165.397.505.71.93.78l.893.15c.543.09.94.56.94 1.109v1.094c0 .55-.397 1.02-.94 1.11l-.893.149c-.425.07-.765.383-.93.78-.165.398-.143.854.107 1.204l.527.738c.32.447.269 1.06-.12 1.45l-.774.773a1.125 1.125 0 01-1.449.12l-.738-.527c-.35-.25-.806-.272-1.203-.107-.397.165-.71.505-.781.929l-.149.894c-.09.542-.56.94-1.11.94h-1.094c-.55 0-1.019-.398-1.11-.94l-.148-.894c-.071-.424-.384-.764-.781-.93-.398-.164-.854-.142-1.204.108l-.738.527c-.447.32-1.06.269-1.45-.12l-.773-.774a1.125 1.125 0 01-.12-1.45l.527-.737c.25-.35.273-.806.108-1.204-.165-.397-.505-.71-.93-.78l-.894-.15c-.542-.09-.94-.56-.94-1.109v-1.094c0-.55.398-1.02.94-1.11l.894-.149c.424-.07.765-.383.93-.78.165-.398.143-.854-.107-1.204l-.527-.738a1.125 1.125 0 01.12-1.45l.773-.773a1.125 1.125 0 011.45-.12l.737.527c.35.25.807.272 1.204.107.397-.165.71-.505.78-.929l.15-.894z"
                  />
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    d="M15 12a3 3 0 11-6 0 3 3 0 016 0z"
                  />
                </svg>
              }
              label="Product Settings"
              open={productSettingsOpen}
              onClick={handleProductSettingsClick}
            >
              <MenuItem
                component={<Link to="/productconfig/0" />}
                style={getMenuItemStyles("/productconfig/0")}
              >
                Configure Product
              </MenuItem>
              <MenuItem
                component={<Link to="/other-expenses" />}
                style={getMenuItemStyles("/other-expenses")}
              >
                Configure Expenses
              </MenuItem>
              <MenuItem
                component={<Link to="/add-exception-rules" />}
                style={getMenuItemStyles("/add-exception-rules")}
              >
                Configure Rules
              </MenuItem>
              <MenuItem
                component={<Link to="/productconfig/1" />}
                style={getMenuItemStyles("/productconfig/1")}
              >
                Configure Pincode
              </MenuItem>
              <MenuItem
                component={<Link to="/productconfig/2" />}
                style={getMenuItemStyles("/productconfig/2")}
              >
                Configure Inventory
              </MenuItem>
              <MenuItem
                component={<Link to="/cashback" />}
                style={getMenuItemStyles("/cashback")}
              >
                Configure Cashback
              </MenuItem>
              <MenuItem
                component={<Link to="/productconfig/3" />}
                style={getMenuItemStyles("/productconfig/3")}
              >
                Configure Payments
              </MenuItem>
              <MenuItem
                component={<Link to="/reviews/6" />}
                style={getMenuItemStyles("/reviews/6")}
              >
                Reviews Modularity
              </MenuItem>
              <MenuItem
                component={<Link to="/sales-defence/2" />}
                style={getMenuItemStyles("/sales-defence/2")}
              >
                Buybox Modularity
              </MenuItem>
            </SubMenu>
            <MenuItem
              icon={
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke-width="1.5"
                  stroke="currentColor"
                  data-slot="icon"
                  class="w-5 h-5"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    d="M12 9.75v6.75m0 0-3-3m3 3 3-3m-8.25 6a4.5 4.5 0 0 1-1.41-8.775 5.25 5.25 0 0 1 10.233-2.33 3 3 0 0 1 3.758 3.848A3.752 3.752 0 0 1 18 19.5H6.75Z"
                  />
                </svg>
              }
              component={<Link to="/downloads" />}
              style={getMenuItemStyles("/downloads")}
            >
              Downloads
            </MenuItem>

            <SubMenu
              title="User Settings"
              icon={
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke-width="1.5"
                  stroke="currentColor"
                  class="w-5 h-5"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    d="M15.75 6a3.75 3.75 0 11-7.5 0 3.75 3.75 0 017.5 0zM4.501 20.118a7.5 7.5 0 0114.998 0A17.933 17.933 0 0112 21.75c-2.676 0-5.216-.584-7.499-1.632z"
                  />
                </svg>
              }
              label="User Settings"
              open={userSettingsOpen}
              onClick={handleUserSettingsClick}
            >
              <MenuItem
                component={<Link to="/account-settings/account" />}
                style={getMenuItemStyles("/account-settings/account")}
              >
                Account Settings
              </MenuItem>
              <MenuItem
                component={<Link to="/account-settings/marketplace" />}
                style={getMenuItemStyles("/account-settings/marketplace")}
              >
                Marketplace Settings
              </MenuItem>
              <MenuItem
                component={<Link to="/account-settings/user" />}
                style={getMenuItemStyles("/account-settings/user")}
              >
                User Management
              </MenuItem>
              <MenuItem onClick={handleLogoutClick}>Logout</MenuItem>
            </SubMenu>
          </Menu>
        </Menu>
      </Sidebar>
      <main className="inner"> {children}</main>
    </div>
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(CustomSideBar);
